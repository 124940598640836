<!--
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-04-26 15:21:48
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-26 20:01:25
-->
<template>
  <div>
    test 父组件
    <t></t>
  </div>
</template>

<script>
import t from '@/components/t'
export default {
  name: 'tP',
  components: {
    t,
  },
  data() {
    return {
      a: '111',
    }
  },
  beforeCreate() {
    console.log('beforeCreated Data:',this.a)
    console.log(`父组件beforeCreate`)
  },
  created() {
    console.log('created Data:',this.a)
    console.log(`父组件created`)
  },
  beforeMount() {
    console.log(this.a)
    console.log(`父组件beforeMount`)
  },
  mounted() {
    console.log(this.a, this._vnode)
    console.log(`父组件Mounted`)
  },
  beforeDestroy() {
    console.log(`父组件beforeDestroy`)
  },
  destroyed() {
    console.log(`父组件destroyed`)
  },
}
</script>
