<!--
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-04-26 15:03:27
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-26 15:17:54
-->
<template>
  <div>test</div>
</template>

<script>
export default {
  name: 't',
  beforeCreate() {
    console.log(`子组件beforeCreate`)
  },
  created() {
    console.log(`子组件created`)
  },
  beforeMount() {
    console.log(`子组件beforeMount`)
  },
  mounted() {
    console.log(`子组件Mounted`)
  },
  beforeDestroy() {
    console.log(`子组件beforeDestroy`)
  },
  destroyed() {
    console.log(`子组件destroyed`)
  },
}
</script>